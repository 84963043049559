<template>
	<div class="container">
    <div class="title">
      <span class="inner_title">新增住户</span>
    </div>
		<div>
      <div class="item-box">
				<span class="item-left">楼栋：</span>
				<el-select v-model="building_id" @change="louDongChange" placeholder="请选择">
          <el-option
            v-for="item in louDongList"
            :key="item.id"
            :label="item.building_number"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">单元：</span>
				<el-select v-model="unit_id"  @change="danYuanChange" placeholder="请选择">
          <el-option
            v-for="item in danYuanList"
            :key="item.id"
            :label="item.unit_number"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">住房：</span>
				<el-select v-model="house_id" placeholder="请选择">
          <el-option
            v-for="item in zhuFangList"
            :key="item.id"
            :label="item.house_number"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">身份类型：</span>
				<el-select v-model="shenfen_id" placeholder="请选择">
          <el-option
            v-for="item in shenFenList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
			<div class="item-box">
				<span class="item-left">用户名：</span>
				<el-input class="rantHouseName" v-model="user_name"   show-word-limit placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">性别：</span>
				<el-radio-group v-model="sex" @change='sexChange'>
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
			</div>
			<div class="item-box">
				<span class="item-left">手机号：</span>
				<el-input class="rantHouseName"  v-model="mobile"  show-word-limit placeholder="请输入"></el-input>
			</div>
      <div class="item-box">
				<span class="item-left">身份证：</span>
				<el-input class="rantHouseName"  v-model="id_number"  show-word-limit placeholder="请输入"></el-input>
			</div>
			<div class="sub-box">
				<el-button class="submitBtn" type="primary" plain @click="submit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>

import {addZhuHuFn} from '@/api/zhuHu.js'
import {getLouDongListSelectFn,getDanYuanSelectFn,getZhuFangSelectFn} from '@/api/select.js'
	export default {
		props: [],
		data() {
			return {
        type:'',//用来判断是从住房下面的人员过来的，还是从住户那边编辑过来的
        louDongList:[],
        building_id:'',

        danYuanList:[],
        unit_id:'',

        zhuFangList: [],
        house_id:'',

        shenFenList:[{
          id:'OWNER',
          name:'业主'
        },{
          id:'MEMBER',
          name:'成员'
        },{
          id:'TENANT',
          name:'租客'
        }],
        shenfen_id:'',

        user_name:'',
        sex:0,
        mobile:'',
        id_number:''
			};
		},
    created() {
      console.log(this.building_id)
      this.getLouDongList()
      if (this.$route.query.type){
        this.type = this.$route.query.type
      }
      if (this.$route.query.building_id){
        this.building_id = parseInt(this.$route.query.building_id)
        this.getDanYuanList()
      }
      if (this.$route.query.unit_id){
        this.unit_id = parseInt(this.$route.query.unit_id)
        this.getZhuFangList()
      }
      if (this.$route.query.house_id){
        this.house_id = parseInt(this.$route.query.house_id)
      }
      
		},
		mounted() {
		},
		methods: {
      //获取单元列表
      getDanYuanList(){
        this.loading = true;
				getDanYuanSelectFn({
          api_token: localStorage.getItem('token1'),
          building_id:this.building_id
				}).then(res => {
					this.danYuanList = res.datas;
					this.loading = false;
				})
      },
      //获取楼栋列表
      getLouDongList() {
				this.loading = true;
				getLouDongListSelectFn({
          api_token: localStorage.getItem('token1'),
				}).then(res => {
					this.louDongList = res.datas;
					this.loading = false;
				})
			},
      //获取住房列表
      getZhuFangList(id) {
				getZhuFangSelectFn({
          api_token: localStorage.getItem('token1'),
          unit_id:id
				}).then(res => {
					this.zhuFangList = res.datas;
				})
			},
      //性别改变
      sexChange(value){
        this.sex = value
      },
      //楼栋改变
      louDongChange(value){
        this.getDanYuanList(value)
      },
      //单元改变
      danYuanChange(value){
        this.getZhuFangList(value)
      },
			//提交
			submit() {
				addZhuHuFn({
					api_token : localStorage.getItem('token1'),
					house_id:this.house_id,
					type:this.shenfen_id,
					user_name:this.user_name,
					sex:this.sex == 0  ? 'MALE' : 'FEMALE',
					mobile:this.mobile,
					id_number:this.id_number,
				}).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
                if (this.type == 'fromZhufang'){
                  this.$router.push({
                    path: "/renYuanListInZhuFang?id=" + this.house_id,
                  });
                } else {
                  this.$router.push({
                    path: "/zhuHuList",
                  });
                }
							}
						});
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import url("../../../style/detail.css");

	.el-upload-box {}

	.el-upload {
		max-width: 500px;
		display: flex;
		flex-wrap: wrap;
		/* overflow: hidden; */
	}
</style>
